import { useAuth } from "react-oidc-context";

export const usePatientId = () => {
  const { user } = useAuth();
  if (!user) {
    throw new Error(
      "No user found. This is usually because the hook is executed outside of an AuthProvider, or because the authentication is not completed yet.",
    );
  }
  // New field since the introduction of device auth
  if (user.profile.user_id) {
    return user.profile.user_id as string;
  }

  // Old field which is the device id when using device auth, and patient id when using patient auth
  return user.profile.sub;
};
