import { msg } from "@lingui/core/macro";
import styles from "./profile.module.scss";
import { useAcceptedPatientNote } from "@/api/patientNote";
import * as Sentry from "@sentry/react";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { NavCard } from "@/routes/profile/navCard";
import { useLingui } from "@lingui/react";
import { InfoNavCard } from "./infoNavCard";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/featureFlags";

export const Profile = () => {
  const {
    data: acceptedPatientNote,
    isPending,
    isError,
    error,
  } = useAcceptedPatientNote();

  const { data: carePatientNote } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CarePatientNote,
  );

  const { _ } = useLingui();
  if (isPending) {
    return <Loading message={_(msg`Hämtar information om din vård`)} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={_(msg`Något gick fel när vi hämtade information om din vård`)}
      />
    );
  }

  return (
    <>
      <div className={styles.navigationCards}>
        {acceptedPatientNote && carePatientNote ? (
          <NavCard
            link={{
              to: "/profile/patientNotes",
              title: _(msg`Detta har hänt`),
              description: _(msg`2 minuters läsning`),
            }}
          />
        ) : null}
        <NavCard
          link={{
            to: "/profile/patientGuide",
            title: _(msg`Din vård`),
            description: _(msg`1 minuts läsning`),
          }}
        />
        <InfoNavCard
          link={{
            to: "/profile/about",
            title: _(msg`Om appen`),
          }}
        />
      </div>
    </>
  );
};
