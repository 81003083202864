import { t } from "@lingui/core/macro";
import { Heading } from "@components/Heading/Heading";
import styles from "./guide.module.scss";
import { GoBackButton } from "@/routes/profile/goBackButton";
import { useNavigate } from "react-router";
import { usePatient } from "@/api/patient";
import { useGuideTemplates } from "./useGuideTemplates";

export const Guide = () => {
  const navigate = useNavigate();
  const { data: patient } = usePatient();
  const patientFirstName = patient ? patient.name.split(" ")[0] : "";

  const template = useGuideTemplates();

  return (
    <div className={styles.container}>
      <div className={styles.goBackButton}>
        <GoBackButton onClick={() => navigate("..")} size="large" />
      </div>
      <>
        <img
          src={template.imagePath[480]}
          srcSet={`${template.imagePath[480]} 480w, ${template.imagePath[768]} 768w, ${template.imagePath[1366]} 1366w`}
          sizes="(max-width: 480px) 480px, (max-width: 768px) 768px, (max-width: 1366px) 1366px"
          width="480px"
          height="270px"
          alt={t`Din vård`}
          className={styles.image}
        />
        <div className={styles.infoText}>
          <Heading level="h1" className={styles.heading}>
            {patient ? t`Hej ${patientFirstName}!` : t`Hej!`}
          </Heading>
          {template.jsxText}
        </div>
      </>
    </div>
  );
};
