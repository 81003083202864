import styles from "@/routes/profile/navCard.module.scss";
import FitnessHeart from "@components/icons/FitnessHeartRate";
import { Text } from "@components/Text/Text";
import { Heading } from "@components/Heading/Heading";
import CaretRightIcon from "@components/icons/CaretRightIcon";
import type { IUnstyledLink } from "@components/Link/Link";
import { UnstyledLink } from "@components/Link/Link";

export const NavCard = ({
  link,
}: {
  link: Pick<IUnstyledLink, "to"> & {
    title: string;
    description: string;
  };
}) => {
  return (
    <UnstyledLink to={link.to}>
      <div className={styles.card}>
        <div className={styles.iconContainer}>
          <FitnessHeart />
        </div>
        <div className={styles.text}>
          <Heading level="h2">{link.title}</Heading>
          <Text element="p" size="small" color="faded">
            {link.description}
          </Text>
        </div>
        <div className={styles.arrowIcon}>
          <CaretRightIcon />
        </div>
      </div>
    </UnstyledLink>
  );
};
