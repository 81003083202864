import { useUnit } from "../../utils/useUnit";
import {
  GuideTemplateAlerisAhc,
  imagePathsAlerisAhc,
} from "./guideTemplates/GuideTemplateAlerisAhc";
import {
  GuideTemplateDefault,
  imagePathsDefault,
} from "./guideTemplates/GuideTemplateDefault";

type IGuideTemplates = {
  jsxText: JSX.Element;
  imagePath: { 480: string; 768: string; 1366: string };
};

export const useGuideTemplates = (): IGuideTemplates => {
  const unit = useUnit();

  if (unit === "alerisahc") {
    return {
      jsxText: <GuideTemplateAlerisAhc />,
      imagePath: imagePathsAlerisAhc,
    };
  } else {
    return {
      jsxText: <GuideTemplateDefault />,
      imagePath: imagePathsDefault,
    };
  }
};
