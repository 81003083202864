import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import styles from "./about.module.scss";
import { GoBackButton } from "@/routes/profile/goBackButton";
import EnvelopeIcon from "@components/icons/EnvelopeIcon";
import ShareExternalLinkIcon from "@components/icons/ShareExternalLinkIcon";
import { useNavigate } from "react-router";
import { Link } from "@components/Link/Link";

export const About = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <GoBackButton onClick={() => navigate("..")} size="large" />
      <div className={styles.content}>
        <Heading level="h2">
          <Trans>Om appen</Trans>
        </Heading>
        <div className={styles.textContainer}>
          <Heading level="h2" size="h3">
            <Trans>Integritetspolicy</Trans>
          </Heading>
          <Text element="span" size="small">
            <Trans>
              För att läsa om hur vi hanterar din integritet, kan du ta del av
              vår integritetspolicy.
            </Trans>
          </Text>
          <Text element="span" size="small">
            <Link
              to={t`https://www.medoma.com/se/privacy-policy`}
              type="external"
            >
              <ShareExternalLinkIcon />
              {t`Integritetspolicy`}
            </Link>
          </Text>
        </div>
        <div className={styles.textContainer}>
          <Heading level="h2" size="h3">
            <Trans>Teknisk support</Trans>
          </Heading>
          <Text element="span" size="small" className={styles.text}>
            <Trans>
              Om du behöver teknisk hjälp, kontakta din vårdgivare. Om du har
              synpunkter eller förbättringsförlag gällande appen är du välkommen
              att kontakta oss.
            </Trans>
            <Link to="feedback@medoma.com" type="email">
              <EnvelopeIcon /> feedback@medoma.com
            </Link>
          </Text>
        </div>
      </div>
    </div>
  );
};
